import classNames from 'classnames'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { withPreview } from 'gatsby-source-prismic'
import { RichText } from 'prismic-reactjs'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import DetailsBox from 'src/components/details-box'
import Highlights from 'src/components/highlights'
import Map from 'src/components/map'
import ResponsiveImage from 'src/components/responsive-image'
import { portfolioPath } from 'src/paths'
import { BbvPageProps, PrismicCompanyProps } from 'src/types'
import { monthYear, year } from 'src/utils/dates'
import ChevronLeft from '../assets/chevron-left.svg'
import Layout from '../components/layout'
import * as styles from './investment.module.scss'

const getHost = (url: string) => {
  try {
    const parsedUrl = new URL(url)
    return parsedUrl.host
  } catch {
    return ''
  }
}

export const query = graphql`
  query Company($uid: String!) {
    prismicCompany(uid: { eq: $uid }) {
      data {
        founded_date
        invested_date
        feature
        image {
          thumbnails {
            large {
              url
              alt
              dimensions {
                height
                width
              }
            }
          }
        }
        logo {
          url
          alt
        }
        founders {
          text
        }
        description {
          raw
        }
        website {
          url
        }
        google_maps_url {
          url
        }
        summary {
          raw
          text
        }
        name {
          raw
          text
        }
        title {
          raw
        }
        co_investors {
          text
        }
        location {
          latitude
          longitude
        }
        highlights {
          highlight {
            document {
              ...highlight
            }
          }
        }
      }
      tags
    }
    prismicSettings {
      ...settings
    }
    prismicLinks {
      ...links
    }
  }
`

const Investment = (props: BbvPageProps<PrismicCompanyProps>) => {
  const { data } = props.data.prismicCompany
  const highlights = data.highlights
    .map((highlight) => highlight.highlight.document)
    .filter((h) => h)

  const settings = props?.data?.prismicSettings?.data

  return (
    <Layout
      title={data.name.text}
      description={data.summary.text}
      imageUrl={data.image.url}
      pageData={props.data}
    >
      <div className="page-container dark-container">
        <Container>
          <div className="d-flex align-items-center">
            <Link to={portfolioPath()} className={classNames(styles.backLink, 'link-light')}>
              <ChevronLeft />
            </Link>
            <div className="h1 m-0">Investment Details</div>
          </div>

          <hr />

          <Row className="flex-column-reverse flex-md-row">
            <Col xs={12} md={8}>
              {!!data.image?.thumbnails?.large && (
                <div className={styles.investmentImage}>
                  <ResponsiveImage
                    image={data.image.thumbnails.large}
                    alt={data.image.alt || data.name.text}
                    className={styles.image}
                  />
                </div>
              )}

              <RichText render={data.title.raw} />

              <div className="body-text">
                <RichText render={data.description.raw} />
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className={styles.sidebar}>
                {!!data.logo && !!data.logo.fixed && (
                  <DetailsBox dark={true} className="p-0 d-flex">
                    <div className={styles.sidebarLogo}>
                      <Img
                        className={styles.sidebarLogoImg}
                        fixed={data.logo.fixed}
                        alt={data.logo.alt || data.name.text}
                      />
                    </div>
                  </DetailsBox>
                )}

                {(!!data.summary.text || !!data.website.url) && (
                  <DetailsBox dark={true}>
                    <div className={styles.summary}>
                      <RichText render={data.summary.raw} />
                      <div>
                        <a href={data.website.url} target="blank" className="link-light">
                          {getHost(data.website.url)}
                        </a>
                      </div>
                    </div>
                  </DetailsBox>
                )}

                {!!data.founded_date && (
                  <DetailsBox dark={true}>
                    <div className={styles.sidebarLabel}>Founded</div>
                    <div className={styles.sidebarValue}>{year(new Date(data.founded_date))}</div>
                  </DetailsBox>
                )}

                {!!data.invested_date && (
                  <DetailsBox dark={true}>
                    <div className={styles.sidebarLabel}>Investment Date</div>
                    <div className={styles.sidebarValue}>
                      {monthYear(new Date(data.invested_date))}
                    </div>
                  </DetailsBox>
                )}

                {!!data.founders.text && (
                  <DetailsBox dark={true}>
                    <div className={styles.sidebarLabel}>Founders</div>
                    <div className={styles.sidebarValue}>{data.founders.text}</div>
                  </DetailsBox>
                )}

                {!!data.co_investors.text && (
                  <DetailsBox dark={true}>
                    <div className={styles.sidebarLabel}>Co-investors</div>
                    <div className={styles.sidebarValue}>{data.co_investors.text}</div>
                  </DetailsBox>
                )}
              </div>

              {!!settings?.google_maps_key && data.location.latitude && data.location.longitude && (
                <div className={styles.location}>
                  <div className="h1 d-none d-md-block">Location</div>
                  <Map
                    lat={data.location.latitude}
                    lng={data.location.longitude}
                    url={data.google_maps_url.url}
                    googleMapsKey={settings.google_maps_key}
                  />
                </div>
              )}
            </Col>
          </Row>
          {highlights.length > 0 && (
            <>
              <hr />
              <Highlights highlights={highlights} type="company" light={true} />
            </>
          )}
        </Container>
      </div>
    </Layout>
  )
}

export default withPreview(Investment)
