import GoogleMapReact from 'google-map-react'
import React from 'react'
import googleMapStyles from './google-map-styles'
import * as styles from './map.module.scss'

const LocationPin = ({ lat, lng, url }: { lat?: number; lng?: number; url?: string }) => (
  <a
    className={styles.mapMarkerContainer}
    href={url || `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
    target="blank"
  >
    <div className={styles.mapMarker} />
  </a>
)

const Map = ({
  lat,
  lng,
  url,
  googleMapsKey,
}: {
  lat: number
  lng: number
  url?: string
  googleMapsKey: string
}) => {
  return (
    <div className={styles.map}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleMapsKey }}
        defaultCenter={{ lat, lng }}
        defaultZoom={11}
        options={{
          styles: googleMapStyles,
          zoomControl: false,
          disableDefaultUI: true,
        }}
      >
        <LocationPin lat={lat} lng={lng} url={url} />
      </GoogleMapReact>
    </div>
  )
}

export default Map
